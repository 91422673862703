
import {
  Component,
  Prop,
  Vue,
} from 'nuxt-property-decorator'
import { ComponentBlocksTextWithPolaroids } from '~/graphql/generated'

@Component
export default class BlocksTextWithPolaroids extends Vue {

  @Prop() readonly data!: ComponentBlocksTextWithPolaroids

  get sectionId(): string {
    return `${this.data.__typename}-${this.data.id}`
  }

  getPolaroidCSSClasses(index: number): string {
    switch (index) {
      case 0:
        // left
        return 'xl:-rotate-6 xl:-translate-x-1/3 xl:w-96'
      case 1:
        // top right
        return 'xl:rotate-2 xl:w-80 xl:-translate-y-1/2 xl:translate-x-1/3'
      case 2:
        // bottom right
        return 'xl:rotate-12 xl:translate-x-1/2 xl:translate-y-1/2 xl:w-72'
      default:
        return ''
    }
  }
}

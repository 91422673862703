
import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Polaroid extends Vue {
  @Prop({
    type: String,
    required: true,
  })
  readonly src!: string

  @Prop({
    type: String,
    required: true,
  })
  readonly smallSrc!: string

  @Prop({
    type: String,
    required: false,
  })
  readonly alt!: string
}
